.captcha-overlay {
  .captcha {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px 20px;
  }

  .btn {
    display: block;
    margin-left: auto;
    margin-top: 15px;
  }

  .captcha-info-text {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .form-input {
    min-width: 180px;
  }
}
