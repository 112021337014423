.widget-toggle {
  .toggle-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .toggle-container {
    width: 42px;
  }

  .toggle-switch-label {
    width: calc(100% - 42px);
    margin-right: 0;
    font-weight: bold;
    text-transform: uppercase;
    box-sizing: border-box;
    padding-left: 20px;
    line-height: 25px;
    white-space: normal;
    cursor: pointer;
  }

  .toggle-switch-description {
    white-space: normal;
    margin-top: 5px;
    box-sizing: border-box;
    padding-left: 62px;
    line-height: 1.4;
  }
}
