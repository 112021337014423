.main-content.has-sidebar.listing-sidebar {
  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
  }

  .sidebar {
    position: sticky;
    top: 0;
    z-index: 90;
    min-height: 100vh;
    align-self: flex-start;
    background-color: var(--primary);
    color: var(--white);
    transition: 0.4s ease max-width, 0.4s ease min-width;

    @media screen and (max-width: 991px) {
      max-width: none;
      width: 100%;
      height: 100vh;
      min-height: 100vh;
      max-height: 100vh;
      overflow: auto;
      will-change: transform;
      transition: 0.4s ease max-height, 0.4s ease min-height;
    }

    &.is-closed {
      width: 0;
      min-width: 70px;
      max-width: 70px;

      @media screen and (max-width: 991px) {
        max-width: 100%;
        min-width: 0;
        width: 100%;
        height: auto;
        min-height: 60px;
        max-height: 60px;
        transition: 0.4s ease max-height;
        overflow: hidden;
      }

      .sidebar-toggle {
        padding-left: 24px;
        background-color: var(--primary);

        @media screen and (max-width: 500px) {
          padding-left: 20px;
        }
      }

      .sidebar-toggle-inner {
        padding-right: 0;
      }

      .sidebar-filter-title {
        opacity: 0;
        width: 0;
        overflow: hidden;
        padding-left: 0;
        transition-delay: 0s, 0s, 0s;

        @media screen and (max-width: 991px) {
          opacity: 1;
          width: auto;
          padding-left: 20px;
        }
      }

      .sidebar-close {
        width: 0;
        overflow: hidden;
        margin: 0;
        opacity: 0;
        transition-delay: 0s, 0s;
      }

      .sidebar-inner {
        opacity: 0;
        width: 100%;
        transition-delay: 0s, 0.4s;
      }
    }

    .sidebar-inner {
      padding-right: 50px;
      padding-top: 0;
      padding-bottom: 215px;
      opacity: 1;
      box-sizing: border-box;
      white-space: nowrap;
      overflow: auto;
      will-change: transform;
      max-height: calc(100vh - 80px);
      transition: opacity 0.2s ease 0.2s, width 0.2s ease 0.2s;

      @media screen and (max-width: 991px) {
        padding-bottom: 50vh;
        overflow: hidden;
        max-height: none;
      }

      @media screen and (max-width: 500px) {
        padding-right: 20px;
      }
    }

    .sidebar-toggle {
      width: 100%;
      background-color: var(--primary-dark);
      box-sizing: border-box;
      padding-left: 80px;
      cursor: pointer;
      transition: padding-left 0.2s ease 0.2s, background-color 0.8s ease 0s;

      &:hover {
        background-color: var(--primary-dark);
      }

      @media screen and (max-width: 1600px) {
        padding-left: 30px;
      }

      @media screen and (max-width: 991px) {
        padding-left: 24px;
        position: sticky;
        top: 0;
        z-index: 20;
      }

      @media screen and (max-width: 500px) {
        padding-left: 20px;
      }
    }

    .icon-sliders {
      height: 28px;
      min-width: 22px;
      transform: scale(0.9);
    }

    .sidebar-toggle-inner {
      height: 80px;
      width: 100%;
      display: flex;
      align-items: center;
      padding-right: 20px;
      box-sizing: border-box;

      @media screen and (max-width: 991px) {
        height: 60px;
      }
    }

    .sidebar-filter-title {
      padding-left: 20px;
      text-transform: uppercase;
      font-weight: 500;
      opacity: 1;
      width: 190px;
      white-space: nowrap;
      transition: width 0.2s ease 0.2s, opacity 0.2s ease 0.2s,
        padding-left 0.2s ease 0.2s;
    }

    .sidebar-close {
      margin-left: auto;
      transform: scale(0.6);
      opacity: 1;
      width: 22px;
      overflow: hidden;
      transition: width 0.2s ease 0.2s, opacity 0.2s ease 0.2s;

      @media screen and (max-width: 991px) {
        transition: none;
      }

      .icon {
        @media screen and (max-width: 991px) {
          transform: rotate(90deg);
        }
      }
    }
  }

  .main-content-inner {
    background-color: var(--offwhite);
    will-change: transform;

    .container {
      margin-left: 35px;

      @media screen and (max-width: 1600px) {
        margin: 0 20px;
      }
    }
  }
}
