.breadcrumbs {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    padding-bottom: 0px;
    padding-top: 10px;
  }

  a {
    color: currentColor;
    text-decoration: none;
    text-decoration: none;
  }

  .back-link {
    display: flex;
    text-transform: uppercase;
    color: var(--black);
    font-size: 14px;
    font-weight: bold;
    margin-right: 58px;
    letter-spacing: 1.82px;
    white-space: nowrap;

    @media screen and (max-width: 768px) {
      display: none;
    }

    .icon {
      color: var(--black);
      width: 16px;
      margin-right: 20px;
    }
  }

  .breadcrumbs-link-wrapper {
    color: var(--lightgray);

    @media screen and (max-width: 768px) {
      margin-top: 15px;
    }

    a:hover {
      color: var(--primary);

      &:after {
        color: var(--lightgray);
      }
    }

    .crumb {
      font-size: 14px;
      letter-spacing: 0.21px;
      line-height: 1.7;

      @media screen and (max-width: 768px) {
        font-size: 12px;
        line-height: 1.4;
      }

      &:after {
        content: "-";
        margin: 0 10px;
      }

      &:last-child:after {
        display: none;
      }
    }
  }
}
