.sidebar {
  font-size: 14px;
  min-width: 410px;
  max-width: 410px;

  @media screen and (max-width: 1600px) {
    min-width: 360px;
    max-width: 360px;
  }

  @media screen and (max-width: 500px) {
      min-width: 100%;
      max-width: 100%;
  }

  .sidebar-inner {
    width: calc(100% - 80px);
    margin-left: auto;      
    padding-top: 20px;
    padding-bottom: 20px;

    @media screen and (max-width: 1600px) {
      width: calc(100% - 30px);
    }

    @media screen and (max-width: 991px) {
      width: calc(100% - 24px);
    }

    @media screen and (max-width: 500px) {
      width: calc(100% - 20px);
    }
  }
}
