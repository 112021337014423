.listing-grid-sorting {
  position: relative;
  display: flex;
  align-items: baseline;
  line-height: 20px;
  margin-right: 20px;

  @media screen and (max-width: 991px) {
    margin-top: 5px;
  }

  &.is-open {
    .listing-grid-sorting-options {
      display: block;
    }

    .toggle-indicator {
      transform: scale(0.5) rotate(180deg);
    }
  }

  .sorting-form {
    position: relative;
  }

  .sorting-label {
    font-size: 14px;
    margin-right: 10px;
    white-space: nowrap;
  }

  .current-sorting {
    cursor: pointer;
    white-space: nowrap;
    font-size: 12px;
  }

  .current-sorting-name {
    margin-right: 15px;
  }

  .listing-grid-sorting-options {
    display: none;
    position: absolute;
    z-index: 1;
    text-align: left;
    left: -10px;
    top: calc(100% + 5px);
    background-color: var(--white);
    white-space: nowrap;
    border: 1px solid var(--border);
    font-size: 12px;
    min-width: calc(100% + 10px);

    .option {
      cursor: pointer;
      padding-bottom: 10px;
      padding: 5px 10px;

      &:hover {
        background-color: var(--border);
      }
    }
  }

  .toggle-indicator {
    display: inline-block;
    color: var(--primary);
    width: 20px;
    transform: scale(0.5);
    transition: transform 0.2s ease 0s;
  }
}
