.detail-title {
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 1200px) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    justify-content: space-between;
    align-items: flex-start;
  }

  .title-text {
    margin-right: 20px;
    padding-top: 0;
    margin-top: -10px;
    @include fluid-type(margin-top, 991px, 1600px, 1px, -10px);
    @include fluid-type(font-size, 991px, 1600px, 24px, 38px);

    @media screen and (max-width: 768px) {
      padding-top: 0;
    }
  }

  .info-icon-container {
    cursor: pointer;
  }

  .info-icon-container .icon {
    width: 30px;
    color: var(--primary);
  }
}
