.account-container {
  max-width: 1200px;
  margin-top: 40px;
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;
  @include fluid-type(margin-top, 991px, 1200px, 20px, 60px);

  .account-heading {
    margin-bottom: 20px;
  }
}

.account-intro-text {
  margin-bottom: 20px;
}

.form-row {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
  }

  &.row-favorite {
    > * {
      margin-top: 10px;
    }

    .form-label {
      width: auto;
      min-width: auto;
      padding-right: 15px;
    }

    input {
      margin-right: 15px;
    }
  }
}

.form-label {
  display: inline-block;
  width: 135px;
  font-size: 16px;
  vertical-align: middle;
  min-width: 135px;
  padding-right: 10px;
  line-height: 1;

  @media screen and (max-width: 500px) {
    width: 100%;
    font-size: 14px;
    margin-bottom: 5px;
    padding-right: 0;
  }
}

.form-input {
  line-height: 16px;
  border: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  padding: 5px 10px 4px 10px;
  height: 36px;
  width: 230px;
  outline: none;
  border: 1px solid $border;
  background-color: var(--offwhite);
  border-radius: 3px;
  font-size: 16px;
  vertical-align: middle;

  &:is(textarea) {
    height: initial;
    line-height: initial;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }

  &:focus {
    outline-style: solid;
    outline-color: var(--black);
    outline-width: 1px;
    background-color: var(--white);
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--lightgray);
    font-weight: 400;
    font-size: 14px;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--lightgray);
    font-weight: 400;
    font-size: 14px;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--lightgray);
    font-weight: 400;
    font-size: 14px;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: var(--lightgray);
    font-weight: 400;
    font-size: 14px;
  }
}

.form-error {
  color: #e7473c;
}

.form-group {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1px solid $border;
  background-color: var(--offwhite);
  gap: 8px 10px;
  width: 100%;
  padding: 20px;

  &.two-cols {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  &.three-cols {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .custom-checkbox {
    column-gap: 10px;
    line-height: 1.2;
  }

  .form-input {
    background-color: white;
  }
}

.form-select {
  line-height: 1.3;
  color: var(--black);
  font-size: 15px;
  padding: 5px 35px 4px 10px;
  height: 36px;
  max-width: 100%;
  width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  margin: 0;
  border: none;
  border-radius: 3px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--offwhite);
  background-image: url("data:image/svg+xml,%3Csvg width='616.1' height='345.1' viewBox='0 0 163 91.3' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath style='fill:%23707070%3Bstroke-width:.494894%3B' transform='rotate(-45 81.3 -111.2)' d='M-61.7-83.2h13.9V32.1h-13.9z'/%3E%3Cpath style='fill:%23707070%3Bstroke-width:.494894%3B' transform='rotate(45 21.1 136.1)' d='M18.3-53.6h13.9V61.7H18.3z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 9px auto;
  border: 1px solid $border;

  @media screen and (max-width: 500px) {
    width: 100%;
  }

  &:focus {
    outline-style: solid;
    outline-color: var(--black);
    outline-width: 1px;
    background-color: var(--white);
  }

  &:disabled:hover,
  &[aria-disabled="true"] {
    border-color: #aaa;
  }
}

.favorite-lists {
  margin-top: 40px;

  .favorite-list {
    margin-top: 40px;
  }

  .favorite-list-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .favorite-list-name {
    > * {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.styled-table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  table-layout: fixed;

  .new-badge {
    margin: 0;
    white-space: nowrap;
  }

  .table-actions {
    position: relative;
    z-index: 1;
  }

  .table-action {
    &:first-child {
      margin-right: 10px;
    }
  }

  .row-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  tr {
    position: relative;
    background-color: transparent;

    @media screen and (max-width: 768px) {
      display: block;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 3px solid var(--black);
    }

    &:first-child {
      @media screen and (max-width: 768px) {
        border-top: 3px solid var(--black);
      }
    }
  }

  td {
    @media screen and (max-width: 768px) {
      display: block;
      font-size: 14px;
      text-align: right;
    }

    &::before {
      @media screen and (max-width: 768px) {
        content: attr(data-label);
        float: left;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 20px;
        padding-right: 10px;
        max-width: 60%;
        text-align: left;
      }
    }

    :last-child {
      @media screen and (max-width: 768px) {
        border-bottom: 0;
      }
    }
  }

  thead {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 4px solid var(--black);

    @media screen and (max-width: 768px) {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    th {
      padding: 20px 5px;

      &.table-page {
        width: 100px;
        @include fluid-type(width, 768px, 1200px, 70px, 100px);
      }

      &.table-date {
        width: 300px;
        @include fluid-type(width, 768px, 1200px, 180px, 300px);
      }

      &.table-options {
        width: 80px;
        text-align: right;
        position: relative;
        z-index: 100;
      }

      &.table-number {
        @include fluid-type(width, 768px, 1200px, 5px, 40px);
      }

      &.table-start {
        @include fluid-type(width, 768px, 1200px, 30px, 80px);
      }

      &.table-end {
        @include fluid-type(width, 768px, 1200px, 30px, 80px);
      }

      &.table-status {
        @include fluid-type(width, 768px, 1200px, 30px, 90px);
      }

      &.table-convert {
        @include fluid-type(width, 768px, 1200px, 60px, 105px);
      }

      &.table-online {
        @include fluid-type(width, 768px, 1200px, 40px, 90px);
      }

      &.table-detail {
        @include fluid-type(width, 768px, 1200px, 50px, 95px);
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: var(--offwhite);

        @media screen and (max-width: 768px) {
          background-color: var(--white);
        }
      }
    }

    td {
      padding: 20px 5px;
      box-sizing: border-box;

      @media screen and (max-width: 768px) {
        max-width: none;
        padding: 10px 5px;
        overflow: hidden;
      }

      &:nth-child(even) {
        @media screen and (max-width: 768px) {
          background-color: var(--offwhite);
        }
      }

      &:last-child {
        text-align: right;
      }
    }
  }
}

.account-import {
  .styled-table {
    margin-top: 20px;
  }
}

.big-form {
  .form-input {
    width: 100%;
  }
}
