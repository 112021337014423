$gutter: 60px;

.detail-grid {
  background-color: var(--white);
  padding-top: 30px;

  .detail-grid-inner {
    display: grid;
    justify-content: space-between;
    margin-top: 20px;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fill, calc(100% / 5 - #{$gutter} / 2));

    @media screen and (max-width: 1600px) {
      grid-template-columns: repeat(auto-fill, calc(100% / 4 - #{$gutter / 2}));
    }

    @media screen and (max-width: 1400px) {
      grid-template-columns: repeat(auto-fill, calc(100% / 3 - #{$gutter / 2}));
    }

    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(auto-fill, calc(100% / 2 - #{$gutter / 2}));
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(auto-fill, calc(100% / 2 - #{$gutter / 6}));
    }

    .mouse-icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: var(--primary);
      position: fixed;
      z-index: 100;
      transform: translate(-50%, -50%) scale(0);
      pointer-events: none;
      transition: 0.2s ease transform;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        color: var(--white);
        width: 22px;
        height: 22px;
        line-height: 22px;
      }

      &.visible {
        transform: translate(-50%, -50%) scale(1);
      }
    }

    .page {
      position: relative;
      margin-bottom: $gutter;
      max-width: 383px;

      @media screen and (max-width: 991px) {
        margin-bottom: $gutter / 2;
      }

      @media screen and (max-width: 768px) {
        max-width: none;
      }

      &:hover {
        .page-image {
          border-color: var(--primary);

          &:after {
            opacity: 0.2;
          }
        }

        img {
          transform: scale(1.05);
        }
      }

      a {
        text-decoration: none;
        color: var(--black);

        &:hover {
          cursor: none;
        }
      }
    }

    .page-title {
      padding-bottom: 5px;
      font-size: 12px;
    }

    .page-image {
      position: relative;
      overflow: hidden;
      height: 0;
      padding-bottom: 133%;
      box-sizing: border-box;
      border: 2px solid var(--black);
      transition: 0.4s ease border-color;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--primary);
        opacity: 0;
        transition: 0.4s ease opacity;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: 0.4s ease transform;
      }
    }
  }

  .detail-grid-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 10px;

    &.pagination-top {
      margin-bottom: 25px;
    }

    .pagination {
      justify-content: flex-start;
    }
  }
}
