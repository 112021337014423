.widget-date-select {

    .date-select {

        label {
            display: block;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .select-label {
            display: inline-block;
            width: 40px;
            font-size: 14px;
        }

        .styled-select {
            max-width: calc(100% - 40px);
            min-width: 70px;
        }

        .btn {
            margin-left: 44px;
        }
    }
}