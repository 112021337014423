.header-main {
  position: relative;
  padding-top: 35px;
  box-shadow: 0px 9px 44px #00000029;
  line-height: 1;
  z-index: 101;

  @media screen and (max-width: 991px) {
    height: 79px;
    padding-top: 20px;
    box-shadow: none;
    border-bottom: 1px solid var(--border);
  }

  &.has-search {
    height: auto;
  }

  a {
    text-decoration: none;
    color: var(--black);

    &:hover .icon {
      color: var(--primary);
    }
  }

  .header-main-inner {
    position: relative;
  }

  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 75px;
    border-top: 4px solid var(--black);
  }

  .header-left {
    display: flex;
    align-items: center;
    width: calc(100% - 380px);

    @media screen and (max-width: 1600px) {
      width: calc(100% - 230px);
    }

    @media screen and (max-width: 1200px) {
      width: calc(100% - 130px);
    }

    @media screen and (max-width: 991px) {
      width: auto;
    }
  }

  .header-logo {
    a {
      display: flex;
    }



    .logo {
      color: var(--primary);
      position: relative;
      @include fluid-type(width, 320px, 500px, 155px, 230px);
      @include fluid-type(height, 320px, 500px, 22px, 30px);
      @include fluid-type(top, 320px, 500px, 2px, 0px);

      &.logo-zeitshift {
        @include fluid-type(width, 320px, 768px, 130px, 150px);
        @include fluid-type(height, 320px, 768px, 25px, 30px);
        @include fluid-type(top, 320px, 500px, 0px, 0px);
      }
    }
  }

  .primary-nav {
    width: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include fluid-type(margin-left, 1100px, 1600px, 30px, 175px);

    @media screen and (max-width: 991px) {
      display: flex;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      padding-bottom: 5px;
      flex-direction: column;
      align-items: flex-start;
      visibility: hidden;
      position: absolute;
      z-index: 101;
      top: 100%;
      left: 0;
      width: 100%;
      background: white;
      padding-left: 20px;
      box-sizing: border-box;
      box-shadow: 0px 40px 40px -30px #00000029;
      transform: scaleY(0);
      transform-origin: center top;
      transition: transform 0.5s ease 0.5s, visibility 0.5s ease 0.5s;

      .nav-item {
        opacity: 0;
        transition: opacity 0.5s ease 0s;
      }

      &.is-open {
        visibility: visible;
        transition: transform 0.5s ease 0s, visibility 0s ease 0s;
        transform: scaleY(1);

        .nav-item {
          transition: opacity 0.5s ease 0.5s;
          opacity: 1;
        }
      }
    }

    &:hover {
      .nav-item.active:after {
        transform: scaleX(0);
      }

      .nav-item.active:hover:after {
        transform: scaleX(1);
      }
    }
  }

  .nav-item {
    position: relative;
    display: flex;
    align-items: center;
    height: 98px;
    @include fluid-type(margin-right, 1450px, 1700px, 30px, 64px);
    white-space: nowrap;
    line-height: 1.6;

    @media screen and (max-width: 1200px) {
      font-size: 15px;
      margin-right: 25px;
    }
    @media screen and (max-width: 1100px) {
      font-size: 15px;
      margin-right: 20px;
    }

    @media screen and (max-width: 991px) {
      display: block;
      height: 45px;
      font-size: 17px;
    }

    a {
      line-height: 98px;

      @media screen and (max-width: 991px) {
        display: block;
        line-height: 30px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      height: 4px;
      background: var(--primary);
      width: calc(100% + 10px);
      bottom: 20px;
      left: -5px;
      opacity: 0;
      pointer-events: none;
      transform: scaleX(0);
      transform-origin: center left;
      transition: transform 0.4s ease 0s, opacity 0s ease .4s;

      @media screen and (max-width: 991px) {
        bottom: 10px;
        left: 0;
        height: 2px;
        width: 100%;
      }
    }

    &:hover:after {
      transform: scaleX(1);
      opacity: 1;
      transition: transform 0.4s ease 0s, opacity 0s ease 0s;
    }

    &.active:after {
      content: "";
      position: absolute;
      height: 4px;
      background: var(--primary);
      width: calc(100% + 10px);
      transform: scaleX(1);
      bottom: 20px;
      left: -5px;
      opacity: 1;
      transition: transform 0.4s ease 0s, opacity 0s ease 0s;

      @media screen and (max-width: 991px) {
        bottom: 10px;
        left: 0;
        height: 2px;
        width: 100%;
      }
    }

    &:last-child {
      margin-right: 0;
      @media screen and (max-width: 1600px) {
        margin-right: 30px;
      }
    }
  }

  .header-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 380px;

    @media screen and (max-width: 1600px) {
      width: 250px;
    }

    @media screen and (max-width: 1200px) {
      width: 200px;
      font-size: 15px;
    }

    @media screen and (max-width: 991px) {
      width: auto;
    }
  }

  .secondary-nav {
    display: flex;
    align-items: center;

    @media screen and (max-width: 550px) {
      position: absolute;
      top: calc(100% - 2px);
      z-index: 101;
      right: 5px;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.5s ease 0s, visibility 0.5s ease 0s;

      &.is-open {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.5s ease 0.5s, visibility 0.5s ease 0.5s;
      }
    }

    .nav-item {
      margin-right: 35px;

      &:last-child {
        margin-right: 0;
      }

      &.active:after {
        @media screen and (max-width: 550px) {
          bottom: 0px;
        }
      }

      &:hover:after {
        @media screen and (max-width: 550px) {
          bottom: 0;
        }
      }

      @media screen and (max-width: 1200px) {
        margin-right: 25px;
      }

      @media screen and (max-width: 991px) {
        display: flex;
        align-items: center;
        height: 60px;
      }

      @media screen and (max-width: 550px) {
        height: auto;
      }
    }
  }

  .header-actions {
    a {
      color: var(--black);
      transition: 0.2s ease color;

      &:hover {
        color: var(--primary);
      }
    }

    .icon {
      width: 27px;
      height: 27px;
      line-height: 27px;
      color: var(--black);
      transition: 0.2s ease color;

      &:last-child {
        margin-left: 20px;
        @include fluid-type(margin-left, 1200px, 1600px, 5px, 20px);
      }
    }
  }

  .mobile-burger-toggle {
    margin-bottom: -5px;
    margin-left: 20px;

    @media screen and (min-width: 992px) {
      display: none;
    }

    @media screen and (max-width: 500px) {
      margin-left: 15px;
    }

    button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 0;
      padding: 0;
    }
  }

  .login-text {
    vertical-align: middle;
    margin-right: 22px;

    @media screen and (max-width: 1600px) {
      margin-right: 10px;
    }

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
}
