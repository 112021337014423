.btn {
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 14px;
  border: none;
  padding: 17px 25px;
  box-sizing: border-box;
  background-color: var(--primary);
  color: var(--white);
  text-transform: uppercase;
  font-weight: bold;
  font-family: $font-sans;
  cursor: pointer;
  transition: 0.4s ease background-color;
  white-space: nowrap;
  text-decoration: none;

  @media screen and (max-width: 991px) {
    padding: 12px 15px;
    font-size: 13px;
  }

  &:hover {
    background-color: var(--primary-dark);
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;

    &:hover {
      background-color: var(--primary);
    }
  }

  &.btn-outline {
    color: var(--white);
    border: 1px solid var(--white);
  }

  &.btn-small {
    padding: 10px 15px;
    text-transform: none;
    font-weight: normal;
    line-height: 1.1;

    @media screen and (max-width: 991px) {
      padding: 10px 15px;
      font-size: 14px;
    }
  }

  &.edit-book-meta {
    display: block;
    text-align: center;
    margin-top: 5px;
  }
}
