.scroll-top-button {
    position: fixed;
    bottom: 14px;
    right: 14px;
  
    @media screen and (max-width: 1600px) {
      display: none;
    }
  
    .btn {
      transform: rotate(90deg);
      padding: 15px;
      width: 55px;
      height: 55px;
  
      .icon {
        width: 19px;
        height: 19px;
        line-height: 16px;
      }
    }
  }