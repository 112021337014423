$header-footer-height: 135px + 137px;

.container {
  margin: 0 80px;

  @media screen and (max-width: 1600px) {
    margin: 0 40px;
  }

  @media screen and (max-width: 991px) {
    margin: 0 20px;
  }
}

.header-main.has-search {
  + .main-content {
    $header-footer-height: 214px + 137px;
    min-height: calc(100vh - #{$header-footer-height});

    @media screen and (max-width: 991px) {
      min-height: calc(100vh - 230px);
    }
  }
}

.main-content {
  $header-footer-height: 135px + 137px;
  min-height: calc(100vh - #{$header-footer-height});

  @media screen and (max-width: 991px) {
    min-height: calc(100vh - 230px);
  }

  &.has-sidebar {
    position: relative;
    display: flex;
  }
}

.main-content-inner {
  padding-bottom: 20px;
  width: 100%;
  min-height: inherit;
  box-sizing: border-box;
}
