$search-bar-height: 78px;

.search-bar {
  position: relative;
  z-index: 100;
  width: 100%;
  padding-left: 50px;
  box-sizing: border-box;
  border-top: 1px solid var(--border);

  @media screen and (max-width: 1600px) {
    padding-left: 0;
  }

  .search-bar-inner {
    background-color: var(--white);
    border-radius: 7px;
    height: $search-bar-height;
    display: flex;
    align-items: center;

    @media screen and (max-width: 991px) {
      height: 60px;
    }

    form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  .search-type-select + .search-bar-input {
    margin-left: 0;
    width: calc(100% - 350px - 80px);
    min-width: 280px;

    @media screen and (max-width: 1200px) {
      width: calc(100% - 95px - 80px);
      min-width: 0;
    }

    @media screen and (max-width: 991px) {
      width: calc(100% - 95px - 60px);
    }

    @media screen and (max-width: 500px) {
      width: calc(100% - 135px);
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 12px;
      width: 1px;
      height: 54px;
      background-color: var(--border);

      @media screen and (max-width: 991px) {
        height: 34px;
      }
    }
  }

  .search-type-select {
    position: relative;
    flex-basis: 364px;
    height: 80px;
    line-height: 80px;
    box-sizing: border-box;
    font-weight: 500;
    z-index: 3;

    @media screen and (max-width: 1200px) {
      flex-basis: 105px;
    }

    @media screen and (max-width: 991px) {
      height: 60px;
      line-height: 60px;
    }

    @media screen and (max-width: 500px) {
      flex-basis: 70px;
    }

    &.is-open {
      .select-box {
        transform: scaleY(1);
        visibility: visible;
        box-shadow: 0px 13px 14px #00000029;

        .type-select-option {
          opacity: 1;
          transition: opacity 0.2s ease 0.4s;
        }
      }

      .toggle-indicator .icon {
        transform: scale(0.5) rotate(180deg);
      }
    }

    .current-type {
      padding-left: 30px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      width: 100%;
      box-sizing: border-box;

      @media screen and (max-width: 991px) {
        padding-left: 24px;
      }

      @media screen and (max-width: 500px) {
        padding-left: 20px;
        width: 75px;
      }
    }

    .current-type-icon {
      display: inline-block;
      margin-right: 20px;

      @media screen and (max-width: 1200px) {
        margin-right: 5px;
      }
    }

    .current-type-label {
      padding-right: 10px;
      white-space: nowrap;
      bottom: -2px;
      position: relative;
      height: 78px;

      @media screen and (max-width: 1200px) {
        display: none;
      }

      @media screen and (max-width: 991px) {
        height: 58px;
      }
    }

    .toggle-indicator {
      position: relative;
      margin-left: auto;
      padding-right: 20px;
      pointer-events: none;
      color: var(--primary);

      @media screen and (max-width: 1200px) {
        padding-right: 15px;
      }

      .icon {
        transform-origin: center center;
        transform: scale(0.5);
        transition: transform 0.2s ease 0s;
      }
    }

    .select-box {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: var(--white);
      line-height: 30px;
      padding-top: 5px;
      margin-top: -7px;
      box-sizing: border-box;
      white-space: nowrap;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      transform: scaleY(0);
      transform-origin: center top;
      box-shadow: 0px 13px 14px #00000000;
      transition: transform 0.4s ease 0s, box-shadow 0.4s ease 0s;

      @media screen and (max-width: 1200px) {
        width: auto;
      }

      @media screen and (max-width: 500px) {
        max-width: calc(100vw - 60px);
        width: calc(100vw - 60px);
      }
    }

    .type-select-option {
      display: flex;
      padding: 6px 30px;
      opacity: 0;
      border: 1px solid var(--white);
      transition: opacity 0.2s ease 0s;
      cursor: pointer;

      @media screen and (max-width: 500px) {
        padding: 6px 20px;
        font-size: 15px;
      }

      &:last-child {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
      }

      &:hover {
        background-color: var(--primary);
        color: white;
      }

      .option-label {
        margin-left: 20px;

        @media screen and (max-width: 500px) {
          margin-left: 20px;
          white-space: normal;
          line-height: 1;
          align-self: center;
        }
      }
    }
  }

  .search-bar-input {
    position: relative;
    width: calc(100% - 80px);

    @media screen and (max-width: 991px) {
      width: calc(100% - 60px);
    }

    input {
      box-sizing: border-box;
      border: none;
      height: 78px;
      width: calc(100% - 2px);
      padding-left: 35px;
      padding-right: 60px;
      border-left: 1px solid var(--border);
      outline: none;
      border-radius: 0;
      font-size: 16px;
      border: none;
      color: var(--primary);
      font-weight: 600;
      background: transparent;

      @media screen and (max-width: 991px) {
        height: 60px;
        padding-left: 20px;
      }

      @media screen and (max-width: 500px) {
        padding-right: 0px;
      }
    }

    &.has-text {
      .search-bar-clear {
        display: flex;
      }

      input {
        @media screen and (max-width: 500px) {
          padding-right: 40px;
        }
      }
    }

    .search-bar-clear {
      display: none;
      position: absolute;
      width: 50px;
      height: 50px;
      justify-content: center;
      align-items: center;
      right: 10px;
      top: 50%;
      color: var(--primary);
      cursor: pointer;
      background-color: var(--white);
      transform: translateY(-50%);
      z-index: 1;

      .icon {
        transform: scale(0.9);
      }

      @media screen and (max-width: 991px) {
        .icon {
          transform: scale(0.8);
        }
      }

      @media screen and (max-width: 500px) {
        right: 0;
        width: 20px;
        padding-right: 10px;
        padding-left: 10px;

        .icon {
          transform: scale(0.8);
        }
      }
    }

    .suggestion-box {
      position: absolute;
      opacity: 0;
      left: 0;
      top: 100%;
      width: 100%;
      z-index: 100;
      background: white;
      box-sizing: border-box;
      padding: 0;
      box-shadow: 0px 13px 14px #00000029;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      padding-top: 5px;
      margin-top: -5px;

      &.is-open {
        opacity: 1;
      }

      li {
        cursor: pointer;
        padding: 10px 35px;

        @media screen and (max-width: 500px) {
          padding: 10px 20px;
        }

        &:last-child {
          padding-bottom: 20px;

          @media screen and (max-width: 500px) {
            padding-bottom: 15px;
          }
        }
      }
    }
  }

  .search-bar-submit button {
    height: 80px;
    width: 80px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    background-color: var(--primary-dark);
    color: var(--white);
    cursor: pointer;
    outline-color: var(--primary);

    @media screen and (max-width: 991px) {
      height: 60px;
      width: 60px;
    }

    .icon {
      color: var(--white);

      @media screen and (max-width: 991px) {
        transform-origin: center center;
        transform: scale(0.8);
        margin-top: -3px;
      }
    }
  }

  .search-bar-help {
    @media screen and (max-width: 500px) {
      display: none;
    }

    .search-bar-help-text {
      display: none;
    }

    .search-bar-help-text-link {
      position: absolute;
      top: 50%;
      right: 100px;
      transform: translateY(-50%);
      text-decoration: none;
      color: black;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid var(--black);
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      @media screen and (max-width: 991px) {
        right: 80px;
      }
    }
  }
}
