.detail-page-accordion {
  border-top: 1px solid var(--black);

  &:last-child {
    margin-bottom: 20px;
    border-bottom: 1px solid var(--black);
  }

  &.is-open {
    .toggle-icon {
      transform: rotate(45deg);
    }
  }

  .accordion-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 33px 0 25px 3px;
    cursor: pointer;

    .icon {
      position: relative;
      top: -2px;
      width: 12px;
      height: 12px;
      line-height: 12px;
      vertical-align: top;
    }
  }

  .toggle-icon {
    width: 12px;
    height: 12px;
    transform: rotate(0deg);
    transform-origin: center center;
    transition: .4s ease transform;
  }

  .accordion-body {
    max-height: 0;
    overflow: hidden;
    transition: 0.4s ease max-height;
    line-height: 24px;
  }

  .accordion-body-inner {
    padding-bottom: 25px;
  }
}
