.page-slider-sidebar {
    position: relative;
    overflow: hidden;
    width: 337px;
    background-color: var(--primary-dark);

    @media screen and (min-width: $breakpoint-xxlarge) {
      width: 500px;
    }

    @media screen and (max-width: 991px) {
      width: 100%;
      height: auto;
    }

    .page-slider-inner {
      position: absolute;
      width: 100%;
      animation: scroll-vertically 100s linear 0s infinite alternate;
      will-change: transform;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 991px) {
        display: block;
        width: max-content;
        height: auto;
        position: relative;
        white-space: nowrap;
        //animation: scroll-horizontally 100s linear 0s infinite alternate;
        animation: none;
        overflow: auto;
        width: 100%;
      }

      &:hover {
        animation-play-state: paused;
      }

      @keyframes scroll-vertically {
        0% {
          transform: translateY(0%);
        }
        100% {
          transform: translateY(
            calc(-100% + 100vh - #{$header-footer-height})
          );
        }
      }

      @keyframes scroll-horizontally {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(calc(-100% + 100vw));
        }
      }

      .page {
        position: relative;
        width: calc(100% - 160px);
        margin: 0 auto;
        margin-bottom: 15px;
        margin-top: 15px;
        height: auto;
        box-shadow: 0px 3px 20px #00000096;

        @media screen and (max-width: 2199px) {
          width: calc(100% - 80px);
        }

        @media screen and (max-width: 991px) {
          width: auto;
          height: 100%;
          margin: 30px 15px;
          display: inline-block;
          flex-shrink: 0;
        }

        img {
          width: 100%;
          display: block;
        }
      }

      .page-year {
        position: absolute;
        bottom: 20px;
        left: 20px;
        padding: 5px 13px;
        box-sizing: border-box;
        background: white;
        color: black;
        font-weight: bold;
        font-size: 14px;
        border: 1px solid var(--border);
        pointer-events: none;
      }
    }
  }