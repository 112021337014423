.sidebar-widget {
  border-bottom: 4px solid var(--white);
  margin-top: 25px;

  &:last-child {
    border-bottom: none;
  }

  .widget-title {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .widget-body {
    padding-bottom: 30px;
  }

  .widget-links {
    margin-top: 10px;
  }

  .widget-filter-active {
    margin-bottom: 5px;

    .active-filter {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--primary-dark);
      padding: 11px 20px;
      box-sizing: border-box;
      font-size: 16px;
      margin-bottom: 7px;

      &:last-child {
          margin-bottom: 0;
      }
    }

    .filter-name {
        white-space: normal;
        padding-right: 5px;
    }

    .filter-remove {
      height: 14px;
      color: var(--white);

      .icon {
        width: 14px;
        height: 14px;
        line-height: 14px;
        vertical-align: top;
      }
    }
  }
}
