.home-component {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page-home {
  $header-footer-height: 135px + 137px;

  .main-content {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--primary);

    @media screen and (max-width: 991px) {
      justify-content: space-between;
      flex-direction: column;
    }

    .main-content-inner {
      width: calc(100% - 337px);
      min-height: 0;

      @media screen and (min-width: $breakpoint-xxlarge) {
        width: calc(100% - 500px);
      }

      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
  }

  .home-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-top: 90px;
    box-sizing: border-box;
    @include fluid-type(padding-top, 1600px, 1920px, 60px, 90px);

    @media screen and (max-width: 768px) {
      padding-top: 40px;
    }
  }

  .home-intro {
    width: 257px;
    line-height: 1.5;
    margin-top: 25px;
    font-weight: 500;

    @media screen and (max-width: 1600px) {
      width: 530px;
      order: 2;
    }

    .home-intro-text {
      display: block;
    }

    .home-intro-link {
      display: inline-block;
      margin-top: 25px;
      color: var(--black);
    }
  }

  .home-content {
    display: flex;
    align-items: center;
    width: calc(100% - 257px);
    padding-left: 180px;
    box-sizing: border-box;

    @media screen and (max-width: 1600px) {
      width: 100%;
      padding-left: 0;
      order: 1;
    }
  }

  .home-content-inner {
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .home-text {
    font-size: 92px;
    @include fluid-type(font-size, 1600px, $page-max, 46px, 92px);
    font-family: $font-serif;
    line-height: 1.5;

    @media screen and (max-width: 1600px) {
      @include fluid-type(font-size, 991px, 1600px, 43px, 72px);
    }

    @media screen and (max-width: 991px) {
      font-size: 58px;
      @include fluid-type(font-size, 320px, 900px, 21px, 58px);
    }

    a {
      position: relative;
      color: var(--white);
      text-decoration: none;

      &:after {
        content: "";
        position: absolute;
        height: 1px;
        transition: 0.8s ease-in-out background-position;
        background-image: linear-gradient(
          90deg,
          #fff,
          #fff 60%,
          transparent 60%,
          transparent 100%
        );
        background-size: 9px 1px;
        background-position: 0% 0%;
        border: none;
        left: 0;
        bottom: 5px;
        width: 100%;

        @media screen and (max-width: 768px) {
          bottom: 0;
        }
      }

      &:hover:after {
        background-position: 10% 0%;
      }
    }
  }

  .home-article-count {
    text-transform: uppercase;
    font-size: 15px;
    margin-top: 20px;
    letter-spacing: 0.56px;
    margin-bottom: 30px;
    line-height: 1.4;
  }

  .search-bar {
    max-width: 900px;
    padding-left: 0;
    border-top: none;

    @media screen and (max-width: 1600px) {
      padding-left: 0;
    }

    @media screen and (max-width: 1600px) {
      width: calc(100% - 52px);
    }

    @media screen and (max-width: 500px) {
      width: 100%;
    }

    .search-bar-inner {
      box-shadow: 0px 9px 63px #00000047;

      @media screen and (max-width: 500px) {
        height: auto;
      }

      form {
        @media screen and (max-width: 500px) {
          flex-wrap: wrap;
        }
      }
    }

    .search-type-select + .search-bar-input {
      width: calc(100% - 350px - 50px);

      @media screen and (max-width: 1200px) {
        width: calc(100% - 95px - 50px);
        min-width: 0;
      }

      @media screen and (max-width: 991px) {
        width: calc(100% - 95px - 60px);
      }

      @media screen and (max-width: 500px) {
        width: calc(100% - 60px);
      }

      + .search-bar-submit button {
        @media screen and (max-width: 500px) {
          border-top-right-radius: 0;
        }
      }
    }

    .search-type-select {
      flex-basis: 320px;

      @media screen and (max-width: 1200px) {
        flex-basis: 95px;
      }

      @media screen and (max-width: 500px) {
        flex-basis: 100%;
        max-width: 100%;
      }

      .current-type {
        @media screen and (max-width: 1200px) {
          padding-left: 30px;
        }

        @media screen and (max-width: 500px) {
          padding-left: 20px;
          width: 100%;
          border-bottom: 1px solid var(--border);
        }
      }

      .current-type-label {
        @media screen and (max-width: 500px) {
          display: block;
          max-width: calc(100% - 90px);
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .current-type-icon {
        @media screen and (max-width: 500px) {
          margin-right: 20px;
        }
      }

      .select-box {
        @media screen and (max-width: 500px) {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .search-bar-input {
      margin-left: 0;
      width: calc(100% - 80px);

      @media screen and (max-width: 991px) {
        width: calc(100% - 60px);
      }

    }

    .search-bar-submit button {
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
    }

    .search-bar-help {
      display: flex;
      justify-content: flex-end;
      margin-top: 35px;

      .search-bar-help-text {
        display: block;
      }

      @media screen and (max-width: 1600px) {
        margin-top: 0;
      }

      @media screen and (max-width: 500px) {
        margin-top: 20px;
        padding-left: 52px;
        justify-content: flex-start;
      }

      .search-bar-help-text-wrapper {
        position: relative;
        display: block;
        color: rgba(white, 0.68);
        width: 260px;
        line-height: 1.5;
        padding-top: 5px;
        font-weight: 500;

        @media screen and (max-width: 1600px) {
          position: static;
          padding-top: 0;

          .search-bar-help-text {
            display: none;
          }
        }

        @media screen and (max-width: 500px) {
          position: relative;
          width: 100%;

          .search-bar-help-text {
            display: block;
          }
        }
      }

      .search-bar-help-text-link {
        color: rgba(white, 0.68);
        border-color: var(--white);
        position: absolute;
        left: -20px;
        top: 0;
        transform: translateX(-100%);

        @media screen and (max-width: 1600px) {
          left: auto;
          right: -20px;
          top: 50%;
          transform: translate(100%, -50%);
        }

        @media screen and (max-width: 500px) {
          right: 100%;
          transform: translateX(-20px);
          top: 0;
        }
      }
    }
  }

  .home-logos {
    margin-top: -100px;
    max-width: 62%;

    @media screen and (max-width: 1600px) {
      margin-top: 20px;
      max-width: 100%;
    }

    .home-logos-inner {
      display: flex;
      align-items: flex-end;
      height: 100px;

      @media screen and (max-width: 1600px) {
        height: auto;
        flex-wrap: wrap;
      }
    }

    .home-logo {
      width: auto;
      max-width: 100%;
      margin-right: 40px;
      margin-bottom: 10px;

      &:last-child {
        margin-right: 0;
      }

      img {
        width: 100%;
      }
    }
  }
}
