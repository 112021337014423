.detail-property {
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding-right: 30px;
  width: calc(100% / 4 - 4px);
  font-size: 14px;

  @media screen and (max-width: 1200px) {
    width: calc(100% / 3 - 4px);
  }

  @media screen and (max-width: 991px) {
    width: calc(100% / 4 - 4px);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }

  .property-title {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.82px;
    text-transform: uppercase;
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
      margin-bottom: 0;
      margin-right: 20px;
      width: 50%;
      max-width: 200px;
    }
  }

  .property-text {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 15px;
      margin-top: -4px;
    }
  }
}
