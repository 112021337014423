html {
  scroll-behavior: smooth;
}

body {
  max-width: $page-max;
  margin: 0 auto;
  background-color: var(--white);

  @media screen and (min-width: ($page-max + 20px)) {
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      width: calc((100vw - #{$page-max + 17px}) / 2);
      background: var(--white);
      height: 100%;
      z-index: 1;
    }

    &:after {
      left: 100%;
      border-left: 1px solid var(--border);
    }

    &:before {
      right: 100%;
      border-right: 1px solid var(--border);
    }
  }

  &.scroll-blocked {
    overflow: hidden;
  }
}

a {
  color: var(--primary);
  line-height: 24px;
}

hr {
  border: none;
  border-top: 4px solid var(--black);
}

.new-badge {
  font-size: 12px;
  letter-spacing: 1.27px;
  line-height: 14px;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--white);
  background-color: var(--primary);
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px 8px;
  margin: 0 10px;
  cursor: default;
  box-sizing: border-box;
  vertical-align: middle;
}

/* class applies to select element itself, not a wrapper element */
.styled-select {
  color: #606060;
  line-height: 1.3;
  padding: 8px 35px 8px 20px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  margin: 0;
  border: none;
  border-radius: 3px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--white);
  background-image: url("data:image/svg+xml,%3Csvg width='616.1' height='345.1' viewBox='0 0 163 91.3' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath style='fill:%23707070%3Bstroke-width:.494894%3B' transform='rotate(-45 81.3 -111.2)' d='M-61.7-83.2h13.9V32.1h-13.9z'/%3E%3Cpath style='fill:%23707070%3Bstroke-width:.494894%3B' transform='rotate(45 21.1 136.1)' d='M18.3-53.6h13.9V61.7H18.3z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  /* arrow icon position (1em from the right, 50% vertical)*/
  background-position: right 10px top 50%;
  /* icon size */
  background-size: 9px auto;

  &:focus {
    color: var(--primary);
  }

  &:disabled,
  &[aria-disabled="true"] {
    color: graytext;
  }

  &:disabled:hover,
  &[aria-disabled="true"] {
    border-color: #aaa;
  }
}

.styled-scrollbars {
  /* Foreground, Background */
  scrollbar-color: var(--primary-dark) var(--primary);
}
.styled-scrollbars::-webkit-scrollbar {
  width: 10px; /* Mostly for vertical scrollbars */
  height: 10px; /* Mostly for horizontal scrollbars */
}
.styled-scrollbars::-webkit-scrollbar-thumb {
  /* Foreground */
  background: var(--primary-dark);
}
.styled-scrollbars::-webkit-scrollbar-track {
  /* Background */
  background: var(--primary);
}

.custom-checkbox {
  line-height: 1.1;
  display: grid;
  grid-template-columns: 25px auto;
  align-items: center;
  text-align: left;

  input[type="checkbox"] {
    cursor: pointer;
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--white);
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: var(--white);
    width: 25px;
    height: 25px;
    border: 1px solid var(--gray);
    border-radius: 50%;
    display: grid;
    place-content: center;
    transition: 0.2s ease background-color;
  }

  input[type="checkbox"]:active {
    background-color: var(--primary);
  }

  input[type="checkbox"]:checked {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  input[type="checkbox"]::before {
    content: "";
    width: 10px;
    height: 10px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    background-color: var(--white);
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  input[type="checkbox"]:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .label-text {
    display: inline-block;
    margin-left: 10px;
  }
}

.grecaptcha-badge {
  z-index: 1;
}

.toggle-switch-label {
  margin-right: 7px;
  vertical-align: middle;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 22px;
  vertical-align: middle;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .toggle-switch-slider {
      background-color: var(--primary-dark);

      &:before {
        transform: translateX(19px);
      }
    }
  }

  .toggle-switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px;

    &:before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 4px;
      bottom: 4px;
      background-color: var(--white);
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}

b,
strong {
  font-weight: 500;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--primary);
  font-weight: 400;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--primary);
  font-weight: 400;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--primary);
  font-weight: 400;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: var(--primary);
  font-weight: 400;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  line-height: 30px;

  &.size-sm {
    width: 22px;
    height: 22px;
    line-height: 22px;
  }

  svg {
    vertical-align: middle;
    fill: currentColor;
  }
}

[x-cloak="all"] {
  display: none !important;
}

@media screen and (max-width: 991px) {
  [x-cloak="mobile"] {
    display: none !important;
  }
}

// Loading animation
.header-inner {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    transform: translateY(-100%);
    background: repeating-linear-gradient(
      to right,
      black 0%,
      white 50%,
      black 100%
    );
    background-size: 200% auto;
    background-position: 0 100%;
    width: 100%;
    opacity: 0;
    animation: gradient 2s infinite;
    animation-timing-function: linear;
    transition: 0.4s ease opacity;

    @-webkit-keyframes gradient {
      0% {
        background-position: 0 0;
      }
      100% {
        background-position: -200% 0;
      }
    }

    @keyframes gradient {
      0% {
        background-position: 0 0;
      }
      100% {
        background-position: -200% 0;
      }
    }
  }
}

.is-loading .header-inner {
  &:after {
    opacity: 1;
  }
}
