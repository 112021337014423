.overlay {
  position: fixed;
  z-index: 1000;
  overflow-y: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(40, 40, 40, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: black;
  white-space: normal;

  .overlay-inner {
    margin: 60px auto;
    width: calc(100% - 40px);
    max-width: 1095px;
    background: var(--white);
    border-radius: 11px;

    @media screen and (max-width: 991px) {
      margin: 50px auto;
    }

    @media screen and (max-width: 991px) {
      margin: 0 auto;
      border-radius: 0;
      width: 100%;
    }
  }

  .overlay-header {
    display: flex;
    padding: 30px 30px 0 30px;

    @media screen and (max-width: 991px) {
      padding: 20px 20px 0 20px;
    }

    .icon {
      color: var(--black);
    }

    &:before {
      content: "";
      width: calc(100% - 60px);
      height: 4px;
      background-color: var(--black);
      margin-right: auto;

      @media screen and (max-width: 991px) {
        width: calc(100% - 50px);
      }
    }
  }

  .overlay-close {
    cursor: pointer;

    @media screen and (max-width: 991px) {
      transform-origin: right top;
      transform: scale(0.8);
    }
  }

  .overlay-body {
    padding: 0 30px 30px 30px;

    @media screen and (max-width: 991px) {
      padding: 0 20px 20px 20px;
    }
  }

  .overlay-info-list {
    padding-right: 40px;
    box-sizing: border-box;

    @media screen and (max-width: 500px) {
      padding-right: 0;
    }

    .info-list-heading {
      font-weight: 500;
      margin-bottom: 5px;
      margin-top: 30px;

      @media screen and (max-width: 768px) {
        margin-top: 15px;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    .info-list-text {
      line-height: 26px;
    }
  }

  .filter-table {
    margin-top: 10px;

    .filter-table-header {
      display: flex;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      border-bottom: 4px solid var(--black);
      height: 40px;
      align-items: center;

      .table-header-col {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        gap: 5px;
        cursor: pointer;
        white-space: nowrap;
        user-select: none;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 17px;
          height: 11px;
          line-height: 1;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        &.asc .sorting-icon {
          .asc-arrow {
            fill: var(--dark);
          }
        }

        &.desc .sorting-icon {
          .desc-arrow {
            fill: var(--dark);
          }
        }
      }

      .sorting-icon {
        transform: scaleX(-1);
        .desc-arrow,
        .asc-arrow {
          fill: #ababab;
        }
      }
    }

    .filter-table-body {
      margin-bottom: 10px;
    }

    .table-header-checkbox {
      min-width: 50px;
      width: 50px;

      @media screen and (max-width: 768px) {
        min-width: 0;
        width: 0;
      }
    }

    .table-header-number {
      min-width: 60px;
      width: 60px;
      text-align: center;

      @media screen and (max-width: 768px) {
        min-width: 70px;
        width: 70px;
      }
    }

    .table-header-title {
      margin-right: auto;
      padding-left: 20px;

      @media screen and (max-width: 768px) {
        padding-left: 5px;
      }
    }

    .table-header-col.table-header-info {
      min-width: 30%;
      width: 30%;

      @media screen and (max-width: 768px) {
        justify-content: flex-end;
      }
    }

    .table-row {
      display: flex;
      align-items: center;
      min-height: 62px;

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }

      &:nth-child(odd) {
        background-color: var(--offwhite);
      }
    }

    .table-col {
      line-height: 25px;
      box-sizing: border-box;
    }

    .col-checkbox {
      min-width: 50px;
      width: 50px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 768px) {
        min-width: 40px;
        width: 40px;
      }
    }

    .col-number {
      text-align: center;
      min-width: 60px;
      width: 60px;

      @media screen and (max-width: 768px) {
        min-width: 30px;
        width: 30px;
      }
    }

    .col-title {
      margin-right: auto;
      padding: 20px;

      @media screen and (max-width: 768px) {
        width: calc(100% - 90px);
        padding: 10px 5px;
        line-height: 1.2;
      }
    }

    .col-info {
      min-width: 30%;
      width: 30%;
      padding-right: 10px;

      @media screen and (max-width: 768px) {
        width: 100%;
        padding: 0 10px 5px 75px;
        line-height: 1.2;
      }
    }

    .filter-table-apply {
      position: sticky;
      bottom: 20px;
      width: 100%;
      background: var(--offwhite);
      border: 1px solid var(--border);
      height: 80px;
      display: flex;
      align-items: center;
      box-shadow: 0px 0px 15px #00000033;
      border-radius: 7px;

      @media screen and (max-width: 991px) {
        bottom: 10px;
        height: auto;
        padding: 10px 0;
      }

      form {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;

        @media screen and (max-width: 991px) {
          padding: 0px 10px;
        }
      }

      .filter-selected-count {
        padding-right: 10px;
      }

      .filter-counter-snippet:after {
        content: " - ";
      }

      .clear-filter {
        text-decoration: underline;
        color: var(--primary);
        cursor: pointer;
      }
    }
  }
}
