.tile {
  position: relative;
  width: 100%;
  margin-bottom: 13px;
  box-shadow: 0px -1px 11px #00000012;
  font-size: 14px;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--primary);
    transition: 0.2s ease opacity;
    opacity: 0;
  }

  &:hover {
    &:after {
      opacity: 1;
    }

    .tile-icon {
      color: var(--primary);
    }

    .tile-category {
      color: var(--primary);

      a {
        color: var(--primary);
      }
    }

    .tile-title,
    .tile-subtitle {
      color: var(--primary);
    }

    .tile-year {
      color: var(--primary);
    }
  }

  .tile-inner {
    background-color: var(--white);
    height: 100%;
    padding: 20px 20px 35px 20px;
    box-sizing: border-box;
  }

  .tile-content-link {
    color: var(--black);
    text-decoration: none;
    line-height: initial;
    display: inline-block;
    width: 100%;
  }

  .tile-actions {
    display: flex;
    align-items: center;

    .icon {
      color: var(--primary);
      width: 19px;
      height: 19px;
      line-height: 19px;
    }

    .tile-action {
      margin-right: 20px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .tile-year {
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: var(--black);
    transition: 0.2s ease color;

    .year-label {
      margin-right: 10px;
    }

    .year-value {
      font-weight: bold;
      font-size: 19px;
    }
  }

  .tile-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .tile-header-left {
    display: flex;
    flex-wrap: wrap;
  }

  .tile-icon {
    display: flex;
    align-items: center;
    color: var(--black);
    white-space: nowrap;
    margin-right: 20px;
    transition: 0.2s ease color;

    .icon {
      width: 19px;
      height: 19px;
      line-height: 19px;
    }

    .tile-icon-text {
      margin-left: 10px;
      line-height: 1;
    }
  }

  .tile-category {
    display: flex;
    align-items: center;
    align-items: baseline;
    width: 100%;
    margin-top: 10px;
    color: var(--black);
    transition: 0.2s ease color;

    .category-label {
      margin-right: 5px;
      white-space: nowrap;
    }

    .category-values {
      font-weight: bold;
    }

    a {
      text-decoration: none;
      color: var(--black);
      transition: 0.2s ease color;
    }
  }

  .tile-title {
    font-size: 19px;
    font-weight: bold;
    color: var(--black);
    line-height: 1.2;
    margin-bottom: 15px;
    color: var(--black);
    transition: 0.2s ease color;

    a {
      color: var(--black);
      transition: 0.2s ease color;
      text-decoration: none;
    }
  }

  .tile-subtitle {
    font-size: 14px;
    font-weight: bold;
    color: var(--black);
    line-height: 20px;
    margin-bottom: 15px;
    margin-top: -10px;
    color: var(--black);
    transition: 0.2s ease color;
  }

  .tile-properties {
    display: flex;
    width: calc(100% - 95px);
    padding-left: 30px;
    box-sizing: border-box;
    flex-direction: column;

    .tile-property {
      display: inline-block;
      line-height: 1.4;
      margin-bottom: 4px;

      &.hide-small {
        display: none;
      }

      &.bigger .property-value {
        font-size: 19px;
        line-height: 1;
      }

      &.unimportant {
        opacity: 0.65;
        margin-top: 5px;
        .property-value {
          font-weight: normal;
        }
      }
    }

    .property-name {
      margin-right: 5px;
      white-space: nowrap;
    }

    .property-value {
      font-weight: bold;
    }
  }

  .tile-text {
    display: none;
  }

  .tile-info {
    display: flex;
    flex-wrap: wrap;
  }

  .tile-thumbnail {
    width: 95px;
    box-sizing: border-box;

    img {
      border: 1px solid $border;

      width: 100%;
      max-width: 100%;
    }
  }

  .tile-number {
    position: absolute;
    right: 20px;
    bottom: 15px;
    font-size: 12px;
  }
}

//Style for big newetter tiles
.listing-grid-inner.big .tile {
  //On Mobile there are no big tiles
  @media screen and (min-width: 650px) {
    .tile-properties {
      .tile-property.hide-small {
        display: inline-block;
      }
    }

    .tile-info {
      display: inline-flex;
      flex-direction: column;
      width: 160px;
    }

    .tile-properties {
      padding-left: 0;
      margin-bottom: 20px;
      margin-top: 15px;
      width: 100%;
    }

    .tile-thumbnail {
      width: 100%;
    }

    .tile-header {
      margin-bottom: 15px;
    }

    .tile-header-left {
      flex-wrap: nowrap;
    }

    .tile-category {
      margin-top: 0;
      line-height: 1;
    }

    .tile-text {
      display: inline-flex;
      width: calc(100% - 164px);
      padding-left: 20px;
      box-sizing: border-box;
      line-height: 26px;
      vertical-align: top;
    }

    &.no-text {
      //Style for big tiles without text

      .tile-info {
        width: 100%;
      }

      .tile-info {
        flex-direction: row;
      }

      .tile-properties {
        width: 55%;
        padding-left: 30px;
        margin-top: 0;
      }

      .tile-thumbnail {
        width: 45%;
      }
    }
  }
}
