.widget-year-select {
  .year-select {
    label {
      display: block;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .select-label {
      display: inline-block;
      width: 40px;
      font-size: 14px;
    }

    .styled-select {
      padding: 8px 50px 8px 20px;
      max-width: calc(100% - 40px);
    }

    .btn {
      margin-left: 44px;
    }
  }
}
