.detail-issue-select {
  width: 100%;

  .wishlist {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    color: var(--primary);
    text-transform: uppercase;
    letter-spacing: 1.82px;
    font-weight: bold;
    padding-bottom: 10px;

    @media screen and (max-width: 991px) {
      align-items: center;
    }

    .wishlist-text {
      cursor: pointer;
    }

    .wishlist-action {
      cursor: pointer;
      padding-bottom: 10px;

      @media screen and (max-width: 991px) {
        padding-bottom: 0;
      }
    }

    .wishlist-selects {
      width: 100%;
    }

    .wishlist-select-list {
      line-height: 30px;
      text-transform: none;
      letter-spacing: 0;

      &:first-child {
        @media screen and (max-width: 991px) {
          padding-top: 5px;
        }
      }

      a {
        text-decoration: none;
        color: var(--black);
        transition: .2s ease color;

        &:hover {
        color: var(--primary);
        }
      }
    }
  }

  .issue-select {
    padding-top: 20px;
    border-top: 4px solid var(--black);
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 991px) {
      padding-bottom: 0;
    }

    .issue-select-text {
      display: inline-block;
      line-height: 1;
      vertical-align: top;
      letter-spacing: 1.82px;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      margin-right: 40px;

      @media screen and (max-width: 500px) {
        margin-right: 20px;
      }
    }

    .date-select-wrapper {
      white-space: nowrap;
      cursor: pointer;
    }

    .issue-select-date {
      display: inline-block;
      font-size: 32px;
      @include fluid-type(font-size, 991px, 1600px, 20px, 28px);
      font-weight: bold;
      line-height: 1;
      margin-right: 40px;

      @media screen and (max-width: 500px) {
        margin-right: 20px;
      }
    }

    .issue-select-action {
      display: inline-block;
      line-height: 1;
      vertical-align: top;
      color: var(--primary);

      @media screen and (max-width: 500px) {
        transform-origin: center top;
        transform: scale(0.8);
      }
    }
  }
}
