.detail-header {
  background-color: var(--offwhite);

  .detail-header-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 20px;
    padding-top: 20px;

    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
    }
  }

  .detail-properties {
    @media screen and (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .detail-header-left {
    width: calc(100% - 370px);
    max-width: 70%;
    padding-right: 40px;

    @media screen and (max-width: 991px) {
      width: 100%;
      max-width: 100%;
      padding-right: 0;
    }
  }

  .detail-header-right {
    width: 370px;
    min-width: 370px;

    @media screen and (max-width: 991px) {
      max-width: none;
      min-width: 0;
      margin-top: 20px;
      width: 100%;
    }
  }
}
