.language-switcher {
  position: relative;

  @media screen and (max-width: 1200px) {
    margin-right: 25px;
  }

  @media screen and (max-width: 500px) {
    margin-right: 20px;
  }

  .current-language {
    cursor: pointer;
    white-space: nowrap;
    line-height: 30px;

    @media screen and (max-width: 991px) {
      font-size: 17px;
    }
  }

  &.is-open {
    .language-switcher-options {
      display: block;
    }

    .toggle-indicator {
      transform: scale(0.5) rotate(180deg);
    }
  }

  .language-switcher-options {
    display: none;
    position: absolute;
    z-index: 200;
    text-align: center;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 10px);
    border-top: 1px solid var(--border);

    a {
      display: block;
      white-space: nowrap;
      padding: 10px;
      box-sizing: border-box;
      background-color: white;
      border-left: 1px solid var(--border);
      border-right: 1px solid var(--border);
      border-bottom: 1px solid var(--border);
    }
  }

  .toggle-indicator {
    display: inline-block;
    color: var(--primary);
    width: 20px;
    transform: scale(0.5);
  }
}