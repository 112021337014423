.detail-page-crop {
  hr {
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  .download-button {
    margin-bottom: 12px;
  }

  .cropper-overlay {
    position: fixed;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /* mobile viewport fix */
    height: -webkit-fill-available;
    /* mobile safari viewport fix */
    height: 100svh;
    z-index: 100000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease 0.2s;

    &.show {
      opacity: 1;
      visibility: visible;

      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #303030;
      }
    }
  }

  .cropper-wrapper {
    box-sizing: border-box;
    border-top: 40px solid var(--gray);
    border-bottom: 40px solid var(--gray);
    height: calc(100% - 60px);

    @media screen and (max-width: 991px) {
      height: calc(100% - 50px);
      border-bottom: 0;
      border-top: 20px solid var(--gray);
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      border-bottom: 0;
    }
  }

  .cropper {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: #5f5f5f;

    @media screen and (max-width: 991px) {
      height: calc(100% - 100px);
    }
  }

  .cropper img {
    display: block;
    /* This rule is very important, please don't ignore this */
    max-width: 100%;
    max-height: 100%;
  }

  .cropper-header {
    height: 60px;
    background-color: var(--black);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 5px;

    @media screen and (max-width: 991px) {
      padding-left: 13px;
      height: 50px;
    }
  }

  .cropper-header-right {
    .cropper-action {
      display: inline-block;
      opacity: 0.5;
      transition: 0.4s ease opacity;
      pointer-events: none;

      &:hover.active {
        opacity: 1;
      }

      &.active {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .cropper-close {
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 60px;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.82px;
    font-weight: bold;

    .cropper-close-text {
      margin-bottom: -2px;

      @media screen and (max-width: 991px) {
        display: none;
      }
    }

    .icon {
      color: var(--primary);
      margin-right: 20px;

      @media screen and (max-width: 991px) {
        margin-right: 5px;
      }
    }
  }

  .cropper-save {
    position: absolute;
    right: 40px;
    bottom: 0px;
    color: white;
    background-color: rgba(#303030, 0.7);
    padding: 20px 25px 20px 29px;
    box-sizing: border-box;
    border-radius: 7px;

    @media screen and (max-width: 991px) {
      padding: 15px 10px 10px 15px;
      background-color: #303030;
      border-radius: 0;
      position: relative;
      right: auto;
      bottom: auto;
      width: 100%;
      text-align: center;
      height: 100px;
    }

    form {
      display: flex;
      flex-direction: column;
    }

    .cropper-save-inputs {
      padding-bottom: 15px;

      [for="cropperDownloadJpg"] {
        margin-right: 20px;
      }
    }
  }

  /* Cropper style overrides */

  .cropper-line {
    background-color: var(--primary);
    opacity: 1;

    &.line-n,
    &.line-s {
      height: 4px;
    }

    &.line-e,
    &.line-w {
      width: 4px;
    }
  }

  .cropper-point {
    background-color: var(--primary);
    opacity: 1;
    width: 10px;
    height: 10px;

    &.point-se {
      width: 10px;
      height: 10px;
    }
  }
}
