$breakpoint-small: 540px;
$breakpoint-medium: 768px;
$breakpoint-large: 991px;
$breakpoint-xlarge: 1920px;
$breakpoint-xxlarge: 2200px;

$page-max: 2560px;

$font-sans: "Roboto", sans-serif;
$font-serif: "Merriweather", serif;

$primary: #8154ef;
$primary-dark: #6743bf;
$white: #fff;
$offwhite: #f2f4f5;
$black: #000;
$gray: #303030;
$lightgray: #777879;
$border: #e5e5e5;
$highlight: rgba(#f9f100, 0.53);

body {
  --primary: #{$primary};
  --primary-dark: #{$primary-dark};
  --white: #{$white};
  --offwhite: #{$offwhite};
  --black: #{$black};
  --gray: #{$gray};
  --lightgray: #{$lightgray};
  --border: #{$border};
  --highlight: #{$highlight};

  &.red {
    --primary: #E7473C;
    --primary-dark: #B93930;
  }

  &.green {
    --primary: #04AA84;
    --primary-dark: #03886a;

    &.page-home .home-text {
      font-family: $font-sans;
      line-height: 1.3;
    }
  }
}
