.image-overlay {
  .overlay-top {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  .overlay-image {
    width: 210px;
    min-width: 210px;

    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }

    img {
      width: 100%;
    }
  }

  .overlay-bottom {
    margin-top: 30px;
  }
}
