.listing-grid-size-switcher {
    display: flex;
    margin-left: auto;

    @media screen and (max-width: 650px) {
        display: none;
    }

    .size-switcher {
        cursor: pointer;
        opacity: .4;

        &.active {
            opacity: 1;
        }

        &:first-child {
            margin-right: 15px;
        }
    }
}