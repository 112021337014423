.main-content.has-sidebar.faq-sidebar {
  
  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
  }

  .sidebar {
    @include fluid-type(margin-top, 991px, 1600px, 60px, 90px);

    @media screen and (max-width: 991px) {
      width: 100%;
      max-width: 400px;
      margin-top: 30px;
    }

    .sidebar-inner {
      border-top: 4px solid var(--black);

      @media screen and (max-width: 991px) {
        width: calc(100% - 40px);
        margin: 0 auto;
      }
    }

    + .main-content-inner {
      @media screen and (max-width: 991px) {
        padding-left: 0;
        min-height: 65vh;
      }
    }
  }

  .sidebar-inner {
    position: sticky;
    top: 20px;

    @media screen and (max-width: 991px) {
      padding-bottom: 0;
    }
  }

  .anchor-navigation {
    li {
      margin-bottom: 20px;
    }

    a {
      text-decoration: none;
      text-transform: uppercase;
      color: var(--black);
      font-size: 14px;
      font-weight: 500;
      transition: 0.4s ease color;

      &:hover {
        color: var(--primary);
      }
    }
  }

  .text-container,
  .image-container {
    padding-left: 20px;
    padding-right: 20px;

    @media screen and (max-width: 991px) {
      padding: 0;
      margin-top: 20px;
    }
  }
}
