@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@700&family=Roboto:wght@400;500;700&display=swap");
/* TODO change serif font to Corporate Font. Not avaiilable on google fonts, a similar font is used */

body {
  font-family: $font-sans;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 400;
  word-break: break-word;
}

.h1,
h1 {
  font-family: $font-serif;
  @include fluid-type(font-size, 991px, 1600px, 23px, 43px);
  @include fluid-type(padding-bottom, 991px, 1600px, 5px, 15px);
  @include fluid-type(padding-top, 991px, 1600px, 5px, 15px);
}

.h2,
h2 {
  font-family: $font-serif;
  @include fluid-type(font-size, 991px, 1600px, 20px, 30px);
  @include fluid-type(padding-bottom, 991px, 1600px, 5px, 15px);
  @include fluid-type(padding-top, 991px, 1600px, 5px, 15px);
}

.h3,
h3 {
  font-family: $font-serif;
  @include fluid-type(font-size, 991px, 1600px, 18px, 25px);
  @include fluid-type(padding-bottom, 991px, 1600px, 5px, 15px);
  @include fluid-type(padding-top, 991px, 1600px, 5px, 15px);
}

.h4,
h4 {
  font-family: $font-serif;
  @include fluid-type(font-size, 991px, 1600px, 17px, 20px);
  @include fluid-type(padding-bottom, 991px, 1600px, 5px, 15px);
  @include fluid-type(padding-top, 991px, 1600px, 5px, 15px);
}

.h5,
h5 {
  font-family: $font-serif;
  @include fluid-type(font-size, 991px, 1600px, 16px, 18px);
  @include fluid-type(padding-bottom, 991px, 1600px, 5px, 15px);
  @include fluid-type(padding-top, 991px, 1600px, 5px, 15px);
}

.h6,
h6 {
  font-family: $font-serif;
  @include fluid-type(font-size, 991px, 1600px, 15px, 16px);
  @include fluid-type(padding-bottom, 991px, 1600px, 5px, 15px);
  @include fluid-type(padding-top, 991px, 1600px, 5px, 15px);
}

p {
  line-height: 24px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.highlight {
  position: relative;
  background-color: var(--highlight);
  display: inline-block;
  font: inherit;
  padding: 0 5px;
  box-sizing: border-box;
  margin-left: -2px;
  margin-right: -2px;
  line-height: 1.5;
}

.highlight-viewer {
  background-color: var(--highlight);
  mix-blend-mode: multiply;
}

ul,
ol {
  padding-left: 18px;

  &.unstyled {
    li {
      padding-bottom: 0;
    }
  }
}

li {
  padding-bottom: 5px;
}
