$gutter: 13px;

//If sidebar is closed, show one more tile
.sidebar.is-closed + .main-content-inner .listing-grid .listing-grid-inner {
  grid-template-columns: repeat(auto-fill, calc(100% / 5 - #{$gutter}));

  @media screen and (max-width: 1800px) {
    grid-template-columns: repeat(auto-fill, calc(100% / 4 - #{$gutter}));
  }

  @media screen and (max-width: 1400px) {
    grid-template-columns: repeat(auto-fill, calc(100% / 3 - #{$gutter}));
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(auto-fill, calc(100% / 2 - #{$gutter / 2}));
  }

  @media screen and (max-width: 650px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }

  &.big {
    //Style for big listing if sidebar is closed
    grid-template-columns: repeat(auto-fill, calc(100% / 3 - #{$gutter}));

    @media screen and (max-width: 1800px) {
      grid-template-columns: repeat(auto-fill, calc(100% / 2 - #{$gutter}));
    }

    @media screen and (max-width: 1400px) {
      grid-template-columns: repeat(auto-fill, calc(100% / 2 - #{$gutter}));
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(auto-fill, 100%);
    }

    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(auto-fill, 100%);
    }

    @media screen and (max-width: 650px) {
      grid-template-columns: repeat(auto-fill, 100%);
    }
  }
}

.listing-grid {
  margin-top: 20px;

  .listing-grid-actions {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: 30px;
    margin-top: 30px;

    @media screen and (max-width: 991px) {
      margin-bottom: 10px;
      margin-top: 20px;
    }
  }

  .listing-grid-inner {
    display: grid;
    justify-content: space-between;
    margin-top: 10px;
    grid-template-columns: repeat(auto-fill, calc(100% / 4 - #{$gutter}));

    @media screen and (max-width: 1800px) {
      grid-template-columns: repeat(auto-fill, calc(100% / 3 - #{$gutter}));
    }

    @media screen and (max-width: 1400px) {
      grid-template-columns: repeat(auto-fill, calc(100% / 2 - #{$gutter / 2}));
    }

    @media screen and (max-width: 1100px) {
      grid-template-columns: repeat(auto-fill, 100%);
    }

    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(auto-fill, calc(100% / 2 - #{$gutter / 2}));
    }

    @media screen and (max-width: 650px) {
      grid-template-columns: repeat(auto-fill, 100%);
    }

    &.big {
      //Style for big listing if sidebar is open
      grid-template-columns: repeat(auto-fill, calc(100% / 3 - #{$gutter}));

      @media screen and (max-width: 2300px) {
        grid-template-columns: repeat(auto-fill, calc(100% / 2 - #{$gutter}));
      }

      @media screen and (max-width: 1400px) {
        grid-template-columns: repeat(auto-fill, 100%);
      }

      @media screen and (max-width: 1100px) {
        grid-template-columns: repeat(auto-fill, 100%);
      }

      @media screen and (max-width: 991px) {
        grid-template-columns: repeat(auto-fill, 100%);
      }

      @media screen and (max-width: 650px) {
        grid-template-columns: repeat(auto-fill, 100%);
      }
    }
  }

  .listing-grid-pagination {
    margin-top: 5px;
  }
}
