.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }

  .pagination-actions {
    display: flex;
    align-items: center;
  }

  .pagination-action {
    margin: 0 5px;

    @media screen and (max-width: 768px) {
      transform: scale(0.9);
      margin: 0 2px;
    }

    a {
      color: var(--black);
      transition: 0.2s ease color;

      &:hover {
        color: var(--primary);
      }
    }
  }

  .pagination-next {
    .icon {
      transform: rotate(180deg);
      margin-top: 4px;
    }
  }

  .pagination-last {
    .icon {
      transform: rotate(180deg);
      margin-top: 4px;
    }
  }

  .pagination-form {
    margin: 0 10px -2px 10px;
    white-space: nowrap;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      margin: 0 5px 0 3px;
    }
  }

  .pagination-text-page {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .pagination-input {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid var(--border);
    line-height: 1;
    padding: 8px 0px;
    text-align: center;
    box-sizing: border-box;
    width: 50px;
    font-size: 16px;
    outline-color: var(--primary);
    border-radius: 3px;
    margin: 0 5px;

    @media screen and (max-width: 768px) {
      margin: 0 2px;
    }

    &:focus {
      outline-color: var(--primary);
    }
  }
}
