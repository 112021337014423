.detail-page-summary {
  margin-bottom: 20px;

  .summary-heading {
    padding: 22px 20px 22px 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }

  .summary-content {
    line-height: 24px;
  }
  

  .summary-content-all-link {
    text-decoration: underline;
    margin-top: 20px;
    cursor: pointer;
  }
}