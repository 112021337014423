.detail-page-switcher {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  .page-nav {
    color: black;
    cursor: pointer;

    .icon {
      vertical-align: text-bottom;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    &.page-next svg {
      transform: rotate(180deg);
    }
  }

  .page-nav-text {
    margin: 0 20px;
  }
}
