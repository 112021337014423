.widget-media-type {

    .media-type {
        color: var(--white);
        display: block;
        display: flex;
        align-items: center;
        text-decoration: none;
        margin-bottom: 15px;

        .icon {
            margin-right: 20px;

            &.icon-cross {
                margin-right: 0;
                width: 14px;
                height: 14px;
                line-height: 14px;
                display: none;
                margin-left: auto;
            }
        }

        .media-type-name {
            white-space: normal;
            line-height: 1.3;
        }


        &.active {
            background-color: var(--primary-dark);
            padding: 11px 20px;

            .icon.icon-cross {
                display: block;
            }
        }

    }
}