.detail-page-view {
  .detail-page-section {
    display: flex;
    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
    }
  }

  .detail-page-wrapper {
    width: calc(100% - 370px);
    display: flex;
    padding-right: 115px;
    @include fluid-type(padding-right, 991px, 1600px, 40px, 115px);
    box-sizing: border-box;
    margin-bottom: 40px;

    @media screen and (max-width: 991px) {
      padding-right: 0;
      margin-top: 20px;
    }

    @media screen and (max-width: 991px) {
      width: 100%;
      margin-bottom: 30px;
    }

    @media screen and (max-width: 550px) {
      margin-top: 0;
    }
  }

  .detail-page-viewer {
    width: calc(100% - 120px);
    margin-right: auto;

    @media screen and (max-width: 1100px) {
      width: calc(100% - 80px);
    }

    @media screen and (max-width: 768px) {
      width: calc(100% - 60px);
    }

    .open-seadragon-element {
      box-shadow: 0px 3px 50px #0000003d;
      background-color: var(--white);
    }

    .openseadragon-canvas:focus {
      outline: none;
    }
  }

  .js-viewer-highlight {
    display: none;
  }

  .detail-page-viewer-controls {
    display: flex;
    flex-direction: column;
    margin-top: 63px;
    background-color: transparent;

    @media screen and (max-width: 1200px) {
      margin-top: 100px;
    }

    @media screen and (max-width: 991px) {
      margin-top: 63px;
    }

    @media screen and (max-width: 550px) {
      margin-top: 40px;
    }
  }

  .page-viewer-control {
    border: none;
    color: black;
    background-color: transparent;
    margin: 0 0 10px 0;
    cursor: pointer;
    padding: 0;
    width: 40px;
    height: 40px;

    @media screen and (max-width: 991px) {
      width: 35px;
      height: 35px;
    }

    &:hover {
      color: var(--primary);
    }

    .icon {
      width: 100%;
      height: 100%;
      transition: 0.2s ease color;
    }
  }

  .page-viewer-highres-toggle {
    position: relative;
    white-space: nowrap;
    font-size: 14px;
    margin-left: auto;

    @media screen and (max-width: 1200px) {
      margin-left: 0;
      margin-top: 20px;
    }

    @media screen and (max-width: 991px) {
      width: auto;
      margin-left: auto;
      margin-top: 0px;
    }

    @media screen and (max-width: 550px) {
      margin-left: 0;
      margin-top: 0;
    }
  }

  .page-viewer-top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px 20px;
    margin-bottom: 30px;
    margin-top: 0;

    @media screen and (max-width: 1200px) {
      margin-bottom: 20px;
    }

    .pagination {
      @media screen and (max-width: 550px) {
        display: none;
      }
    }

    .page-viewer-top-left {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    .back-to-overview-link {
      display: flex;
      align-items: center;
      color: black;
      text-transform: uppercase;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: .8px;
      font-weight: bold;
      font-size: 14px;
      transition: .2s ease color;

      &:hover {
        color: var(--primary);
      }

      .icon {
        position: relative;
        top: -1px;
        margin-right: 10px;
        color: var(--primary);
      }

      span {
        display: inline-block;
        margin-bottom: -2px;
      }
    }
  }

  .page-viewer-bottom {
    margin-bottom: 0;
    margin-top: 30px;

    .pagination {
      justify-content: flex-start;
    }
  }

  .detail-page-info {
    width: 370px;

    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
}

.open-seadragon-element.fullpage {
  max-height: calc(100vh - 50px);
}

//Need to be globally styled, beacause openseadragon attaches them to the dom if in fullscreen mode
.detail-page-viewer-controls {
  &.fullpage {
    padding: 10px;
    background-color: var(--offwhite);
    text-align: right;

    .page-viewer-control {
      background: transparent;
      border: none;
      outline: none;
      color: var(--black);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 0;
      padding: 0;
      margin: 0 2px;
      cursor: pointer;
    }
  }
}
