.datepicker-overlay {
  .year-switcher {
    margin-top: 10px;
  }

  .year-button {
    padding: 5px 15px;
    border: 2px solid var(--border);
    color: var(--black);
    text-decoration: none;
    display: inline-block;
    margin-bottom: 4px;
    box-shadow: 0px 2px 2px #00000015;
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.2s ease color, 0.2s ease background-color,
      0.2s ease border-color;

    &.current {
      border-color: var(--primary);
      cursor: default;

      &:hover {
        color: var(--black);
        background-color: transparent;
      }
    }

    &:hover {
      background-color: var(--primary);
      color: white;
      border-color: var(--primary);
    }
  }

  .calendar {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .calendar-month {
    display: inline-block;
    width: calc(100% / 3);
    padding: 0 10px;
    box-sizing: border-box;
    max-width: 290px;
    margin-bottom: 40px;
    margin: 20px 0;

    @media screen and (max-width: 991px) {
      width: calc(100% / 2);
      max-width: 100%;
      padding: 0 20px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: 400px;
      margin: 10px auto 20px auto;
      padding: 0;

      &:last-child {
        margin-bottom: 0;
      }

      &:first-child {
        margin-top: 20px;
      }
    }
  }

  .calendar-month-name {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.34px;
  }

  .calendar-display {
    width: 100%;
    margin: 20px auto 0 auto;
  }

  .calendar-weekday {
    font-size: 14px;
    width: calc(100% / 7 - 4px);
    text-align: center;
    display: inline-block;
    white-space: nowrap;
    margin-bottom: 20px;
    color: var(--lightgray);
  }

  .calendar-noday {
    width: calc(100% / 7 - 4px);
    display: inline-block;
    height: 1px;
    color: #fff;
  }

  .calendar-day {
    display: inline-flex;
    width: calc(100% / 7 - 4px);
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1;
    color: var(--black);
    aspect-ratio: 1/1;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-sizing: border-box;

    &.current {
      border: 2px solid var(--primary);
    }

    &.occasion {
      color: var(--white);
      background-color: var(--primary);

      a {
        color: var(--white);
        text-decoration: none;
      }
    }
  }
}
