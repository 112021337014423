.download-pages {
    
    .download-pages-cta {
        all: unset;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: black;
        text-transform: uppercase;
        letter-spacing: .8px;
        font-weight: bold;
        font-size: 14px;
        transition: .2s ease color;

        &:hover {
            color: var(--primary);
        }

        .icon {
            position: relative;
            top: -3px;
            margin-right: 15px;
            color: currentColor;
        }
    }
}