.footer-main {
  height: 135px;
  display: flex;
  align-items: center;
  background-color: var(--gray);
  color: white;
  font-size: 14px;

  @media screen and (max-width: 768px) {
    height: auto;
    padding: 20px 0;
  }

  &.white {
    background-color: var(--white);

    a {
      color: var(--black);
    }

    .footer-nav {
      color: var(--black);
    }

    .licence-info-text {
      color: var(--black);
    }
  }

  a {
    text-decoration: none;
    color: rgba(white, 0.4);
  }

  .footer-wrapper {
    width: 100%;
  }

  .footer-inner {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  .footer-left {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    white-space: nowrap;
    margin-right: 40px;

    @media screen and (max-width: 768px) {
      margin-right: 0;
    }

    @media screen and (max-width: 500px) {
      white-space: normal;
    }
  }

  .footer-info {
    display: flex;
    margin-bottom: -2px;
    margin-right: 33px;

    @media screen and (max-width: 768px) {
      margin-right: 20px;
      flex-wrap: wrap;
      flex-direction: column;
    }

    @media screen and (max-width: 500px) {
      margin-right: 0;
    }

    .footer-logo {
      max-width: 296px;
      width: 100%;
      display: flex;
      align-items: flex-end;
      margin-bottom: 2px;
      margin-right: 20px;

      @media screen and (max-width: 768px) {
        margin-bottom: 10px;
      }

      &:last-child {
        @media screen and (max-width: 768px) {
          margin-bottom: 0;
        }
      }

      img {
        width: 100%;
      }
    }
  }

  .footer-nav {
    color: rgba(white, 0.4);

    @media screen and (max-width: 768px) {
      margin-top: 10px;
    }
  }

  .powered-by {
    padding-right: 20px;
    box-sizing: border-box;
    display: inline-block;
  }

  .footer-right {
    display: flex;
    align-items: flex-end;
  }

  .licence-info-text {
    color: rgba(white, 0.4);
    margin-right: 33px;
    text-align: right;

    @media screen and (max-width: 1400px) {
      display: none;
    }
  }

  .licence-info-image img {
    vertical-align: bottom;
    margin-top: 10px;
  }
}
