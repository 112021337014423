.widget-link-search {
  .widget-search {
    position: relative;
    width: 100%;
    height: 36px;
    margin-bottom: 15px;

    &:before {
      // content: "";
      // position: absolute;
      // left: 13px;
      // top: 10px;
      // width: 1px;
      // height: 16px;
      // background-color: var(--black);
    }

    input {
      width: 100%;
      height: 100%;
      line-height: 16px;
      border: none;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      box-sizing: border-box;
      padding: 5px 20px;
      outline: none;
      border-radius: 3px;
      font-weight: bold;
      color: var(--primary);

      @media screen and (max-width: 991px) {
        //To prevent page zoom on iOS, the font-size needs to be at least 16px.. 
        //Only using a media query is not a perfect solution. (iPads?)
        //Is it worth detecting user agent for iOS and applying the styles only there?
        font-size: 16px; 
        padding-bottom: 3px;
      }
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--primary);
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--primary);
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: var(--primary);
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: var(--primary);
    }
  }

  .widget-link {
    display: block;
    color: white;
    line-height: 1.1;
    padding: 8px 0;
    text-decoration: none;
    font-size: 14px;

    &.disabled {
      display: none;
    }

    .widget-link-name {
      display: inline;
      word-break: normal;
      white-space: normal;
    }
  }

  .widget-show-all {
    margin-top: 20px;
    text-decoration: underline;
    cursor: pointer;
  }
}
