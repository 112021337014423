.tile.artwork-tile {

  .tile-properties {
    width: 100%;
    padding-left: 0;
    margin-top: 10px;
    box-sizing: border-box;
  }

  .tile-thumbnail {
    width: 100%;
    height: 220px;
    max-height: 220px;
    background: var(--offwhite);
    padding: 10px;
    box-shadow: inset 0 0 7px 0px #0000001a;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border: none;
    }
  }
}

//Style for big artwork tiles
.listing-grid-inner.big .tile.artwork-tile {
  //On mobile there are no big tiles
  @media screen and (min-width: 650px) {
    .tile-title {
      margin-bottom: 25px;
    }

    .tile-properties {
      width: 100%;
      margin-top: 15px;
      margin-bottom: 0;
      display: block;
      column-count: 2;

      .tile-property {
        display: block;
      }
    }

    .tile-info {
      width: 100%;
    }

    .tile-thumbnail {
      height: 330px;
      max-height: 330px;
    }
  }
}
